import {
  FeedbackItem,
  ListParticipantStepsResponse,
  ParticipantSection,
  ParticipantStepState,
  Participant,
  ParticipantStep,
} from '@wix/ambassador-challenges-v1-participant/types';

import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';

import {
  getSelfPacedSettings,
  isFlexible,
  isSelfPaced,
  isSpecific,
} from '../../../../selectors/challenges';
import isSameDay from 'date-fns/isSameDay';
import flatten from 'lodash/flatten';

import { getRightDateFromBackend } from '../../../../selectors/dates';
import { IQuestionnaireType } from '../components/Questionnaire/interfaces';
import { ISettingsContextValue } from '@wix/tpa-settings';
import challengeSettings from '../../settingsParams';
import { isAfter } from 'date-fns';
import userTypeHandlers from '../../../../contexts/User/helpers/userTypeHandlers';
import { isSectionLocked } from '../../../../selectors/sections';
import { useParticipantSteps } from '../../../../contexts/ParticipantStepsDataProvider/ParticipantStepsContext';
import { useSections } from '../../../../contexts/ParticipantSections/ParticipantSectionsContext';

const STEP_TOOLTIP_KEY = {
  [ParticipantStepState.COMPLETED]: 'step.tooltip.completed',
  [ParticipantStepState.OVERDUE]: 'step.tooltip.missed',
  [ParticipantStepState.PENDING]: 'step.tooltip.pending-scheduled',
  [ParticipantStepState.RUNNING]: 'step.tooltip.running',
  'PENDING-SPC': 'step.tooltip.pending-spc',
  'PENDING-SCHEDULED': 'step.tooltip.pending-scheduled',
  STEP_COMPLETION_DISABLED: 'step.tooltip.complete-not-allowed',
};

export enum ChallengeEntityType {
  STEP = 'STEP',
  SECTION = 'SECTION',
  VOID = 'VOID',
}

export interface INextEntity {
  section?: ParticipantSection;
  step?: ParticipantStep;
  type: ChallengeEntityType;
}

export interface ISiteColor {
  name: string;
  value: string;
  reference: string;
}
const addTransparentBg = (colors: ISiteColor[]): ISiteColor[] => {
  return colors.map((color) => {
    // if (color?.name === 'color_11') {
    //   return {
    //     ...color,
    //     value: 'transparent',
    //   };
    // }

    return color;
  });
};

const getBaseViewOptions = (challenge: Challenge) => {
  const showHeaderDate = isSpecific(challenge);

  const stepsCount = challenge?.stepsSummary?.stepsNumber;
  const showSteps = !!stepsCount;
  const showDuration =
    isFlexible(challenge) ||
    isSpecific(challenge) ||
    (isSelfPaced(challenge) && getSelfPacedSettings(challenge)?.duration);
  const showParticipants = !!challenge.participantsSummary.participantsNumber;

  const overviewItemsCount =
    Number(showSteps) + Number(showDuration) + Number(showParticipants);

  return {
    showHeaderDate,
    showSteps,
    stepsCount,
    showDuration,
    showParticipants,
    overviewItemsCount,
  };
};

const getBaseViewOptionsForVisitor = (
  challenge: Challenge,
  settings: ISettingsContextValue,
) => {
  const {
    showHeaderDate,
    showSteps,
    stepsCount,
    showDuration,
    showParticipants,
    overviewItemsCount,
  } = getBaseViewOptions(challenge);

  const showStepsForVisitor =
    showSteps && settings.get(challengeSettings.displayChallengeSteps);
  const showDurationForVisitor =
    showDuration && settings.get(challengeSettings.displayChallengeDuration);
  const showParticipantsForVisitor =
    showParticipants &&
    settings.get(challengeSettings.displayChallengeParticipants);

  return {
    showHeaderDate,
    stepsCount,
    showSteps: showStepsForVisitor,
    showDuration: showDurationForVisitor,
    showParticipants: showParticipantsForVisitor,
    showOverview:
      showStepsForVisitor ||
      showDurationForVisitor ||
      showParticipantsForVisitor,
    overviewItemsCount,
  };
};

const getBaseViewOptionsForSidebar = (
  challenge: Challenge,
  settings: ISettingsContextValue,
) => {
  const {
    showHeaderDate,
    showSteps,
    stepsCount,
    showDuration,
    showParticipants,
    overviewItemsCount,
  } = getBaseViewOptions(challenge);

  return {
    showHeaderDate,
    showSteps:
      showSteps &&
      settings.get(challengeSettings.sidebarLayoutDisplayChallengeSteps),
    stepsCount,
    showDuration:
      showDuration &&
      settings.get(challengeSettings.sidebarLayoutDisplayChallengeDuration),
    showParticipants:
      showParticipants &&
      settings.get(challengeSettings.sidebarLayoutDisplayChallengeParticipants),
    overviewItemsCount,
  };
};

const getResolveStepData = (
  currentStep: ParticipantStep,
  savedQuestionnaireData: any = {},
  formQuizData: { isFormQuiz: boolean; quizSubmissionId: string } = {
    isFormQuiz: false,
    quizSubmissionId: null,
  },
) => {
  const _isStepResolved = isStepResolved(currentStep);
  const currentStepAfterUpdate = { ...(currentStep || {}) };

  if (!_isStepResolved) {
    currentStepAfterUpdate.transitions = [
      {
        state: ParticipantStepState.COMPLETED,
        occurredAt: new Date(),
      },
    ];
  }

  if (formQuizData?.isFormQuiz) {
    currentStepAfterUpdate.quizSubmission = {
      quizSubmissionId: formQuizData?.quizSubmissionId,
      score: 100, // for the future
    };
  } else {
    const [isQstnSettled, qstnItems] = getSavedQuestionnaireData(
      savedQuestionnaireData || {},
      IQuestionnaireType.CLASSIC,
    );
    const [isQuizSettled, quizItems] = getSavedQuestionnaireData(
      savedQuestionnaireData || {},
      IQuestionnaireType.QUIZ,
    );

    if (isQstnSettled || isQuizSettled) {
      currentStepAfterUpdate.feedback = {
        items: isQstnSettled ? qstnItems : undefined,
        quiz: isQuizSettled ? quizItems : undefined,
      };
    }
  }

  return {
    isStepResolved: _isStepResolved,
    currentStepAfterUpdate,
  };
};

const getSavedQuestionnaireData = (
  storage,
  name,
): [boolean, FeedbackItem[]] => {
  const savedData = storage[name];
  const isDataSettled = !!savedData;
  const data = isDataSettled
    ? Object.keys(savedData || {}).map((fieldId) =>
        fieldToBackendFormat(fieldId, savedData[fieldId]),
      )
    : null;

  return [isDataSettled, data];
};

const fieldToBackendFormat = (fieldId: string, field: any): FeedbackItem => {
  return {
    feedbackItemSettingsId: fieldId,
    [field.type]: field.value,
  };
};

const getStepsResolvedValue = (steps: ParticipantStep[]) => {
  return Math.floor(
    (steps.reduce(
      ((resolved: number, step: ParticipantStep) => {
        return isStepResolved(step) ? ++resolved : resolved;
      }) as any,
      0,
    ) /
      (steps.length || 1)) *
      100,
  );
};

export const getNextStepToComplete = (
  flatStepsList: ParticipantStep[] = [],
): ParticipantStep => {
  return (
    getFirstAvailableStep(flatStepsList) ||
    flatStepsList.find((step) => {
      return utils.isStepLocked(step);
    })
  );
};

export const getFirstAvailableStep = (
  flatStepsList: ParticipantStep[] = [],
): ParticipantStep => {
  return (
    flatStepsList.find((step) => {
      return isStepAvailableForComplete(step);
    }) ||
    flatStepsList.find((step) => {
      return isStepOverdue(step);
    })
  );
};

const getLastCompletedStep = (
  flatStepsList: ParticipantStep[] = [],
): ParticipantStep => {
  return flatStepsList
    .slice(0)
    .reverse()
    .find((step) => {
      return isStepResolved(step);
    });
};

const isStepHidden = (
  challenge: Challenge,
  flatStepsList: ParticipantStep[], // should be sorted properly for the SPC
  step: ParticipantStep,
) => {
  const isSPC = isSelfPaced(challenge);
  const isResolveStepsInOrderSettingEnabled =
    !!challenge?.settings?.accessRestrictions?.resolveStepsInOrder;
  const isHideFutureStepsSettingEnabled =
    !!challenge?.settings?.accessRestrictions?.hideFutureSteps;
  const _isStepResolved = isStepResolved(step);

  const isSPCStepFirstAvailable =
    getFirstAvailableStep(flatStepsList)?.id === step?.id;
  const isScheduledStepInTheFuture = isAfter(
    getRightDateFromBackend(step?.dateFrame?.start),
    new Date(),
  );

  const isStepQuizSubmitted = step?.quizSubmission?.quizSubmissionId;
  const formId = step?.source?.settings?.general?.quiz?.quizId;
  const isQuiz = !!formId;

  if (isQuiz && isStepQuizSubmitted) {
    return false;
  }

  const isRequirementsForHideStepComplied = !_isStepResolved;

  return isSPC
    ? isRequirementsForHideStepComplied &&
        !isSPCStepFirstAvailable &&
        isResolveStepsInOrderSettingEnabled &&
        isHideFutureStepsSettingEnabled
    : isRequirementsForHideStepComplied &&
        isHideFutureStepsSettingEnabled &&
        isScheduledStepInTheFuture;
};

const isVisibleStepLockedForComplete = (
  challenge: Challenge,
  flatStepsList: ParticipantStep[], // should be sorted properly for the SPC
  step: ParticipantStep,
) => {
  const isSPC = isSelfPaced(challenge);
  const isResolveStepsInOrderSettingEnabled =
    !!challenge?.settings?.accessRestrictions?.resolveStepsInOrder;
  const isHideFutureStepsSettingEnabled =
    !!challenge?.settings?.accessRestrictions?.hideFutureSteps;
  const _isStepResolved = isStepResolved(step);

  const isSPCStepFirstAvailable =
    getFirstAvailableStep(flatStepsList)?.id === step?.id;

  /*
    For SPC we can set `resolve steps in order` but without hiding further steps.
   */

  return (
    !_isStepResolved &&
    isSPC &&
    !isSPCStepFirstAvailable &&
    isResolveStepsInOrderSettingEnabled &&
    !isHideFutureStepsSettingEnabled
  );
};

export type ChallengeStepData = {
  listParticipantSections: ParticipantSection[];
  participantSteps: ListParticipantStepsResponse;
};

export const isStepFailed = (step: ParticipantStep): boolean => {
  return step.transitions?.['0']?.state === ParticipantStepState.FAILED;
};

const utils = {
  STEP_TOOLTIP_KEY,
  addTransparentBg,
  getBaseViewOptions,
  isStepFailed,
  getStepTooltipKey: (
    step: ParticipantStep,
    isUnavailable: boolean,
    isSPC: boolean,
    participant: Participant,
  ) => {
    if (
      (isStepOverdue(step) || isStepPending(step) || isStepRunning(step)) &&
      isParticipantCompletedChallenge(participant)
    ) {
      return STEP_TOOLTIP_KEY.STEP_COMPLETION_DISABLED;
    }

    if (isUnavailable) {
      return isSPC
        ? STEP_TOOLTIP_KEY['PENDING-SPC']
        : STEP_TOOLTIP_KEY['PENDING-SCHEDULED'];
    }

    if (isStepPending(step)) {
      return isSameDay(new Date(step?.dateFrame?.start), new Date())
        ? STEP_TOOLTIP_KEY.RUNNING
        : isSPC
        ? STEP_TOOLTIP_KEY['PENDING-SPC']
        : STEP_TOOLTIP_KEY['PENDING-SCHEDULED'];
    }

    return STEP_TOOLTIP_KEY[step.transitions[0].state];
  },

  getBaseViewOptionsForVisitor,
  getBaseViewOptionsForSidebar,

  isStepLocked: (step: ParticipantStep) => {
    return !isStepAvailableForComplete(step) && isStepPending(step);
  },

  isQuizFailed: (step: ParticipantStep) => {
    return step?.quizSubmission?.quizSubmissionId && isStepFailed(step);
  },

  isFeedbackFormRequired: (step: ParticipantStep) => {
    const challengeStepIndividualSettings =
      step?.source?.settings?.general?.individual;
    const isQuestionnaireRequired =
      challengeStepIndividualSettings?.confirmationRequired &&
      Object.keys(challengeStepIndividualSettings?.feedbackSettings).length;
    const isQuizRequired =
      challengeStepIndividualSettings?.showQuiz &&
      Object.keys(challengeStepIndividualSettings?.quizSettings).length;

    return !!(
      challengeStepIndividualSettings &&
      (!!isQuestionnaireRequired || !!isQuizRequired)
    );
  },

  getResolveStepData,
  getNextEntity(
    stepId: string,
    data: { currentSection?: ParticipantSection } & ChallengeStepData,
  ): INextEntity {
    if (data.listParticipantSections.length) {
      if (!stepId) {
        const currSectionIdInList = data.listParticipantSections.findIndex(
          (s) => s.id === data.currentSection?.id,
        );
        return {
          section: data.listParticipantSections[currSectionIdInList + 1],
          type: ChallengeEntityType.SECTION,
        };
      }

      const nextSection = data.listParticipantSections.reduce(
        (_nextSection, section, index, sections) => {
          if (!stepId) {
            return sections[index + 1];
          }

          if (section.steps[section.steps.length - 1]?.id === stepId) {
            return sections[index + 1];
          }
          return _nextSection;
        },
        undefined,
      );

      if (nextSection) {
        return {
          section: nextSection,
          type: ChallengeEntityType.SECTION,
        };
      }
    }
    const nextStep = utils.getNextStep(stepId, getFlatStepsList(data));

    if (nextStep) {
      return {
        step: nextStep,
        type: ChallengeEntityType.STEP,
      };
    }

    return {
      type: ChallengeEntityType.VOID,
    };
  },

  getNextButtonLabel(entity: ChallengeEntityType): string {
    switch (entity) {
      case ChallengeEntityType.STEP:
        return 'challenge.page.next-step';
      case ChallengeEntityType.SECTION:
        // return 'challenge.page.next-section'; //todo once localization will be done uncomment
        return 'challenge.page.steps.continue';
      case ChallengeEntityType.VOID:
        return '';
    }
  },

  getNextStep(stepId: string, steps: ParticipantStep[] = []) {
    const currentStepInd = steps.findIndex((step) => step.id === stepId);

    return steps[currentStepInd + 1] || null;
  },

  getStepsResolvedValue,

  scrollToChallengePage: () => {
    const challengePageDataHook = 'challenge-page';

    document
      .querySelector(`[data-hook="${challengePageDataHook}"]`)
      ?.scrollIntoView({
        behavior: 'smooth',
      });
  },

  getHeaderAlignByType: (settings: ISettingsContextValue) => {
    return settings.get(challengeSettings.sidebarLayoutTextAlignment);
  },

  getContentAlignByType: (settings: ISettingsContextValue) => {
    return settings.get(challengeSettings.sidebarLayoutTextAlignment);
  },

  getFirstAvailableStep,
  getLastCompletedStep,

  isStepHidden,
  isVisibleStepLockedForComplete,

  getPassingGrade: (step: ParticipantStep, isFilled: boolean) => {
    return isFilled
      ? utils.getParticipantPassingGrade(step)
      : utils.getOwnerPassingGrade(step);
  },

  getOwnerPassingGrade: (step: ParticipantStep) => {
    return step?.source?.settings?.general?.quiz?.passingGrade;
  },

  getParticipantPassingGrade: (step: ParticipantStep) => {
    return step?.quizSubmission?.passingGrade;
  },

  wait: async () => {
    await new Promise((resolve) => {
      setTimeout(resolve, 0);
    });
  },
};
export const isStepResolved = (step: ParticipantStep): boolean => {
  return step?.transitions?.['0']?.state === ParticipantStepState.COMPLETED;
};

export const getFlatStepsList = (data: ChallengeStepData) => {
  // both for the sections and without them
  const {
    listParticipantSections = [],
    participantSteps: { steps = [] } = {},
  } = data;
  const isSections = listParticipantSections.length;

  return isSections
    ? flatten(
        listParticipantSections
          .filter((s) => !isSectionLocked(s))
          .map((section) => section.steps || []),
      )
    : steps;
};

export const isStepOverdue = (step: ParticipantStep): boolean => {
  return step?.transitions?.['0']?.state === ParticipantStepState.OVERDUE;
};

export const isStepRunning = (step: ParticipantStep): boolean => {
  return step?.transitions?.['0']?.state === ParticipantStepState.RUNNING;
};

export const isStepPending = (step: ParticipantStep): boolean => {
  return step?.transitions?.['0']?.state === ParticipantStepState.PENDING;
};

export const isParticipantCompletedChallenge = (
  participant: Participant,
): boolean => {
  return userTypeHandlers.isCompleted(participant?.transitions?.[0]?.state);
};

export const isStepAvailableForComplete = (step: ParticipantStep) => {
  return (
    step?.transitions?.['0'] &&
    (isStepRunning(step) ||
      isStepFailed(step) ||
      (isStepPending(step) &&
        isSameDay(getRightDateFromBackend(step.dateFrame.start), new Date())))
  );
};

export const useNextEntity = (stepId: string) => {
  const { listParticipantSections } = useSections();
  const { participantSteps } = useParticipantSteps();

  return {
    nextEntity: utils.getNextEntity(stepId, {
      listParticipantSections,
      participantSteps,
    }),
  };
};

export default utils;
