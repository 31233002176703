import React from 'react';
import { contextFactory } from '../../services/contextFactory';
import { ISocialGroupsContext, ISocialGroupsProviderProps } from './interfaces';

export const { context, ContextProvider, withConsumer } = contextFactory<
  ISocialGroupsContext,
  ISocialGroupsProviderProps
>({
  defaultValues: {
    socialGroup: null,
    isGroupsInstalled: false,
  },
  displayName: 'SocialGroupsProvider',
  propsToContext(props): ISocialGroupsContext {
    return {
      ...props,
    };
  },
});

export const SocialGroupsContext = context;
export const SocialGroupsProvider = ContextProvider;
export const useSocialGroups = () => React.useContext(SocialGroupsContext);
